"use client";
/* eslint-disable react-hooks/rules-of-hooks */
import { zodValidator } from "@tanstack/zod-form-adapter";
import { useForm } from "@tanstack/react-form";
import { toast } from "react-toastify";
import { isAxiosError } from "axios";
import { z } from "zod";
import { useLoginService, useMessage } from "../../../../API";
import { useThrottler } from "../../../../hooks";
import { useGlobalStore } from "../../../../zustand/initStore";
import { ILoggedInUser } from "../../../../../types";
import { env } from "process";

export const useLoginForm = ({hasQueryParam}: {hasQueryParam: boolean}) => {
  const { isTooManyRequests, rateLimit } = useThrottler();
  const { mutateAsync } = useLoginService({hasQueryParam});
  const { setUser, setRole } = useGlobalStore();

  const throttlerMessage = useMessage({
    key: "auth",
    property: "THROTTLER_ERROR",
  });

  const validators = {
    email: {
      onChange: z.string().min(1, { message: "E-mail je obavezan." }).email("Nije validan format e-maila."),
      onChangeAsyncDebounceMs: 500,
    },
    password: {
      onChange: z.string(),
      onChangeAsyncDebounceMs: 500,
    },
  };
  const form = useForm({
    defaultValues: {
      email: "",
      password: "",
    },
    onSubmit: async ({ value }) => {
      try {
        const localStorageItem = localStorage.getItem("rateLimitLogin");
        const currentTime = new Date().getTime();
        if (localStorageItem) {
          const rateLimitParsedData = JSON.parse(localStorageItem);
          const rateLimitDate = new Date(rateLimitParsedData.currentTime).getTime();

          const differenceInMilliseconds = Math.abs(rateLimitDate - currentTime);
          const differenceInSeconds = Math.floor(differenceInMilliseconds / 1000);

          if (differenceInSeconds < 60) {
            const remainingTime = rateLimitParsedData.remainingTime - differenceInSeconds;

            toast.update(rateLimitParsedData.toastKey, {
              render: throttlerMessage + ` (${remainingTime}s)`,
              pauseOnHover: true,
              toastId: rateLimitParsedData.toastKey,
              autoClose: false,
              closeButton: false,
            });

            if (remainingTime > 0) {
              toast.error(rateLimitParsedData.toastKey, {
                pauseOnHover: true,
                toastId: rateLimitParsedData.toastKey,
              });
              rateLimit("rateLimitLogin", rateLimitParsedData.toastKey, remainingTime);
            }
          } else {
            localStorage.removeItem("rateLimitLogin");
          }
          return;
        }

        const response = await mutateAsync(value);

        setUser(response.data as unknown as ILoggedInUser);
        // @ts-ignore
        const role = response.data.staff?.role;
        setRole(role);
        const fullName = response.data.staff.firstName + " " + response.data.staff.lastName;
        const message = useMessage({
          key: "auth",
          property: "SUCCESSFULLY_LOGGED_IN",
          messagePayload: fullName,
        });
        toast.success(message);
        if(typeof window !== 'undefined' && process.env.NEXT_PUBLIC_NODE_ENV === 'development') {
          localStorage.setItem('accessToken', response.data.accessToken);
          localStorage.setItem('refreshToken', response.data.refreshToken);
          localStorage.setItem('staff', JSON.stringify(response.data.staff));
        }


      } catch (error) {
        if (isAxiosError(error)) {
          const response = error.response?.data;
          const errorMessage = useMessage({
            isError: true,
            key: "auth",
            errorResponse: response,
          });
          if (response?.statusCode === 429) {
            rateLimit("rateLimitLogin", errorMessage, 60);
          }

          toast.error('Neispravni podaci za prijavu');
        }
      }
    },

    validatorAdapter: zodValidator,
  });

  return { validators, form, isTooManyRequests };
};
