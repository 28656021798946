/* eslint-disable react/no-children-prop */
"use client";
import { useSearchParams } from "next/navigation";
import Link from "next/link";
import { Input, Button } from "../../../../components/ui";
import { MailIcon, PasswordIcon } from "../../../../public/icons";
import { useLoginForm } from "./useLoginForm";

export default function LoginForm() {
  const searchParams = useSearchParams();
  const isMobile = searchParams.get("isMobile");
  const hasQueryParam = !!isMobile;
  const { validators, form, isTooManyRequests } = useLoginForm({hasQueryParam});

  return (
    <div className="flex flex-col items-center justify-center w-full max-w-xl px-4 py-8 mx-auto space-y-12 flex-1">
      <h1 className="text-3xl font-semibold">Prijavi se</h1>
      <form
        onSubmit={(e) => {
          e.preventDefault();
          e.stopPropagation();
          void form.handleSubmit();
        }}
        className="flex flex-col space-y-12 w-full items-center"
      >
        <form.Field
          name="email"
          validators={validators.email}
          children={(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              leftIcon={<MailIcon />}
              label="E-mail"
              type="email"
              errorMessage={
                field.state.meta.errors[0]?.toString().includes("required")
                  ? "Email is required."
                  : field.state.meta.errors[0]?.toString().split(", ")
              }
              placeholder="tvoj@email.com"
            />
          )}
        />

        <form.Field
          name="password"
          validators={validators.password}
          children={(field) => (
            <Input
              id={field.name}
              name={field.name}
              value={field.state.value}
              onBlur={field.handleBlur}
              onChange={(e) => field.handleChange(e.target.value)}
              leftIcon={<PasswordIcon />}
              label="Šifra"
              type="password"
              placeholder="Šifra"
              errorMessage={field.state.meta.errors[0]?.toString().split(", ")}
            />
          )}
        />
        <div className="flex justify-end w-full">
          <Link
            prefetch={false}
            href="/auth/forgot-password"
            className="text-primary text-sm hover:scale-105 transition-all duration-300 "
          >
            Zaboravio sam šifru!
          </Link>
        </div>
        <form.Subscribe
          selector={(state) => [
            state.canSubmit,
            state.isSubmitting,
            state.values.email,
            state.values.password,
          ]}
          children={([canSubmit, isSubmitting, email, password]) => (
            <Button
              title="Prijavi se"
              disabled={
                Boolean(!canSubmit) ||
                Boolean(isSubmitting) ||
                email.toString().length === 0 ||
                password.toString().length === 0 ||
                isTooManyRequests
              }
              loading={Boolean(isSubmitting)}
              intent="primary"
              type="submit"
            />
          )}
        />
      </form>
    </div>
  );
}
